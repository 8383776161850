import { get } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import ProductSearch from '../components/ProductSearch';
import URI from 'urijs';

import { getPopups } from '../selectors';
import { createNewFeaturePopup } from '../actions/popup';
import { oauth } from '../utils';
import { window } from '../global';

class ProductsApp extends Component {

  constructor(props) {
    super(props);
    this.headerContainer = null;
    this.state = {
      paddingTop: this.getTopPadding(),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;

    window.addEventListener('resize', this.handleResize);

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getTopPadding() {
    return this.headerContainer ? this.headerContainer.offsetHeight : (window.innerWidth <= 1023 ? 145 : 120);
  }

  handleResize(e) {
    const paddingTop = this.getTopPadding();
    if (this.state.paddingTop !== paddingTop) {
      this.setState({ paddingTop });
    }
  }

  render() {
    const { identity, popups } = this.props;
    const url = new URI(window.location.href);
    const keyword_sku = get(url.search(true), "keyword_sku");
    const supplier_popup = get(url.search(true), "supplier_popup");

    const originalStyle = {
      top: 0,
      left: 0,
      zIndex: 100,
      width: '100%',
      height: '100%',
      background: 'white',
      position: 'absolute',
      overflow: 'hidden'
    };

    if(supplier_popup) {
      return (
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary>
            <MainSection popups={popups} no_sidebar={true}>
              <div style={{ ...originalStyle, display: 'flex', flexDirection: 'column' }}>
                <ProductSearch isProductsPage={true} supplier_popup={supplier_popup} keyword_sku={keyword_sku} identity={identity} />
              </div>
            </MainSection>
            <Overlay popups={popups} />
          </ErrorBoundary>
        </DndProvider>
      );
    }else{
      return (
        <DndProvider backend={HTML5Backend}>
          <ErrorBoundary>
            <Header headerContainerRef={(el) => {
              if (!this.headerContainer) {
                this.headerContainer = el;
                setTimeout(() => {
                  this.handleResize();
                });
              }
            }}>
              <div className="columns">
                <h4 style={{ display: 'inline-block' }}>Products</h4>
              </div>
            </Header>
            <MainSection popups={popups}>
              <div className="main-content" style={{
                ...originalStyle, paddingTop: this.state.paddingTop, marginTop: 0,
                display: 'flex', flexDirection: 'column',
              }}>
                <ProductSearch isProductsPage={true} identity={identity} />
              </div>
            </MainSection>
            <Overlay popups={popups} />
          </ErrorBoundary>
        </DndProvider>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  popups: getPopups(state),
  user_id: state.identity.user_id
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsApp);
